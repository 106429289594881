.raw-data-table > * .ant-table-cell-row-hover {
  background-color: inherit !important;
}
.raw-data-table > * td.ant-table-cell-fix-left,
.raw-data-table > * td.ant-table-cell-fix-left-last {
  background-color: inherit !important;
}

.raw-data-table > * tbody > * td.ant-table-column-sort {
  background-color: rgba(0, 0, 0, 0.03);
}

.raw-data-table > * th.ant-table-column-sort {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.exchangeRate {
  border-radius: 6px;
	border-color: #d9d9d9;
	background-color: rgba(0, 0, 0, 0.04);
	width: 50%;
	text-align: center;
	padding: 6px;
}
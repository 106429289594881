.raw-data-table > * .ant-table-cell-row-hover {
  background-color: inherit !important;
}
.raw-data-table > * td.ant-table-cell-fix-left,
.raw-data-table > * td.ant-table-cell-fix-left-last {
  background-color: inherit !important;
}

.raw-data-table > * tbody > * td.ant-table-column-sort {
  background-color: rgba(0, 0, 0, 0.03);
}

.raw-data-table > * th.ant-table-column-sort {
  background-color: rgba(0, 0, 0, 0.05) !important;
}


.unique-totals-table td,
.unique-totals-table th,
.ant-table-container >.ant-table-content >table >thead>tr>th {
    text-align: center;
}



.even-row {
  background-color: #ffffff;
}

.odd-row {
  background-color: #E5E7EB
}

.cloned-row {
  background-color: #D2EBF8; 
  color: #333333;
}

.target-table > * tr.ant-table-row-level-0,
.target-table > * tr.ant-table-row-level-0 .ant-table-cell-fix-left-last {
  background-color: #d6eaf9;
  font-weight: 600;
}

.target-table > * tr.ant-table-row-level-1 {
  font-size: small;
}

.target-table > * td,
.target-table > * th {
  border-color: rgb(200, 210, 210) !important;
}

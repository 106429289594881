.client-service-table > * .ant-table-cell-row-hover {
	background-color: inherit !important;
}
.client-service-table > * td.ant-table-cell-fix-left,
.client-service-table > * td.ant-table-cell-fix-left-last {
	background-color: inherit !important;
}

.client-service-table > * td.ant-table-cell-fix-right,
.client-service-table > * td.ant-table-cell-fix-left-first {
	background-color: inherit !important;
}

.client-service-table > * tbody > * td.ant-table-column-sort {
	background-color: rgba(0, 0, 0, 0.03);
}

.client-service-table > * th.ant-table-column-sort {
	background-color: rgba(0, 0, 0, 0.05) !important;
}

.platform-subtable > * td.ant-table-cell-fix-left,
.platform-subtable > * td.ant-table-cell-fix-left-last {
	background-color: white;
}

.client-service-table .platform-subtable > * td.ant-table-cell-row-hover{
 background-color: #EAEAEA !important;
}

.client-service-table > * tbody > * td {
	font-size: 12px;
}

.hidden {
	display: none;
}

.ant-table-thead > tr > th.ant-table-column-sort {
	background-color: #f0f0f0 !important; /* Puedes cambiar este color */
}

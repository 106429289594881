.financesTable > * tr.ant-table-row-level-0 {
  background-color: #d6eaf9;
  font-weight: 600;
  font-size: medium;
}

.financesTable > * tr.ant-table-row-level-1 {
  background-color: #f4fafc;

  font-weight: 600;
}

.financesTable > * tr.ant-table-row-level-2 {
  font-size: small;
}

.financesTable > * td,
.financesTable > * th {
  border-color: rgb(200, 210, 210) !important;
}
